<template>
  <div class="d-inline-block" v-if="user">
    <v-hover v-slot:default="{ hover }">
      <v-avatar 
        @click="selectUser()"
        :color="!photoUrl ? color : 'transparent'" 
        :min-height="`${size}`" 
        :min-width="`${size}`" 
        :max-height="`${size}`" 
        :max-width="`${size}`" 
        :rounded="rounded"
        class="user-photo"
        :tile="tile"
      >
        <template v-if="!photoUrl">
          <span class="white--text font-weight-bold heading-font" :style="{ fontSize: size/3 + 'px' }">{{ getInitial(user.fullName) }}</span>
        </template>
        <template v-else>
          <v-img :src="photoUrl" :alt="`${user.fullName}`" :style="{ height: 'auto', width: 'auto' }"></v-img>
        </template>

        <v-overlay v-if="hover && editable" absolute>
          <v-btn icon @click="openDialog()">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-overlay>
      </v-avatar>
    </v-hover>

    <template v-if="editable">
      <v-dialog
        v-model="status.uploadPhotoDialog"
        max-width="450"
        persistent
      >
        <v-card>
          <v-card-title class="font-weight-bold">Upload Photo</v-card-title>
          <v-card-text>
            <v-form ref="photoForm">
              <v-progress-linear
                v-if="status.uploadProgress"
                :value="status.uploadProgress"
                height="4"
                color="primary"
                class="mb-2"
                reactive
              ></v-progress-linear>

              <v-file-input
                v-model="data.avatar"
                placeholder="Select Photo"
                prepend-icon=""
                prepend-inner-icon="mdi-account-box"
                accept="image/*"
                ref="photoInput"
                :rules="[rules.required]"
                outlined
              ></v-file-input>

              <v-btn
                @click="validateForm()"
                :loading="status.uploadingPhoto"
                color="accent white--text"
                class="mr-2 gradient"
                depressed
              >Upload</v-btn>

              <v-btn
                @click="closeDialog()"
                :disabled="status.uploadingPhoto"
                depressed
              >Cancel</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import firebase from 'firebase'
import rules from '@/rules'

export default {
  name: 'UserPhoto',

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    id: String,
    size: Number,
    tile: Boolean,
    rounded: Boolean,
    editable: Boolean,
    photoSize: String,
    enableSelection: Boolean,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      photoUrl: null,
      user: null,
      color: null,
      colors: [
        'pink',
        'purple',
        'deep-purple',
        'indigo',
        'blue',
        'light-blue',
        'cyan',
        'teal',
        'green',
        'light-green',
        'orange',
        'deep-orange',
        'blue-grey',
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.user.data,
      status: state => state.user.status,
      currentUser: state => state.user.user,
      users: state => state.users.users,
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'currentUser.avatar': function () {
      this.getUrl(this.id)
    },

    'id': function (val) {
      this.getUrl(val)
    },

    'users': function () {
      if (!this.user) this.getUrl(this.id)
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('user', [
      'uploadPhoto'
    ]),

    getInitial(name) {
      let nameArray = name.split("")
      return nameArray[0]
    },

    openDialog() {
      this.$store.commit('user/setUploadPhotoDialog', true)
    },

    closeDialog() {
      this.$store.commit('user/setUploadPhotoDialog', false)
      this.$refs.photoForm.reset()
    },

    validateForm() {
      if (this.$refs.photoForm.validate()) {
        this.uploadPhoto()
      }
    },

    getUrl(id) {
      if (this.currentUser && this.currentUser.userid == id) this.user = this.currentUser
      else this.user = this.users.find(user => user.userid == id)

      if (this.user) {
        if (this.user.avatar) {
          let url = this.$store.getters['images/getProfileUrl']({ id: this.user.userid, size: this.photoSize })
          
          if (url) this.photoUrl = url
          else {
            var storage  = firebase.storage()
  
            storage.ref(`profilephotos/${this.photoSize}_${this.user.avatar}`)
            .getDownloadURL()
            .then(url => {
              this.photoUrl = url
              this.$store.commit('images/addProfileUrl', { id: this.user.userid, size: this.photoSize, url })
            })
            .catch(error => {
              console.log(error.message)

              url = this.$store.getters['images/getProfileUrl']({ id: this.user.userid, size: 'original' })

              if (url) this.photoUrl = url
              else {
                storage.ref(`profilephotos/${this.user.avatar}`).getDownloadURL()
                .then(url => {
                  this.photoUrl = url
                  this.$store.commit('images/addProfileUrl', { id: this.user.userid, size: 'original', url })
                })
              }
            })
          }
        }
        else {
          this.photoUrl = null
        }
      }
      else {
        this.$store.dispatch('users/getUserUid', id)
      }
    },

    getRandomColor() {
      return this.colors[Math.floor(Math.random() * this.colors.length)]
    },

    selectUser() {
      if(this.enableSelection) {
        this.$emit('userSelect')
      }
    }
  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  created() {
    this.getUrl(this.id)
    this.color = this.getRandomColor()
  }

}

</script>

<style lang="scss">
.user-photo {
  border-width: 1px !important;
  border-color: white !important;
  border-style: solid !important;
  box-shadow: 0 3px 10px 5px rgba(19,45,61,0.1);
}
</style>
